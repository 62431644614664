<!--
 * @FileDescription: 自定义搜索选择组件
 * @Author: 朱建波
 * @Date: 2023-10-19
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZSearchSelect
 *
 * @Props
 * @property {Boolean} select              传入默认选中值 [{label:显示的文字,value:值}]
 * @property {Boolean} api                 请求数据的接口地址
 * @property {Boolean} data                请求数据的参数
 * @property {Boolean} placeholder         placeholder值
 * @property {Boolean} searchKey           请求数据参数key
 * @property {Boolean} label               数据展示文字的key
 * @property {Boolean} value               数据值的key
 * @property {Boolean} text                没有数据显示的内容
 *
 * @Slots
 * empty                                   自定义空数据内容
 *
 * @Methods
 * submit                                  提交触发事件
-->
<script>
import http from "@/api";
export default {
  name: 'ZSearchSelect',
  props: {
    select: {
      type: Array,
      default: () => []
    },
    api: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: '请输入关键字'
    },
    searchKey: {
      type: String,
      default: 'keyword'
    },
    label: {
      type: String,
      default: 'label'
    },
    value: {
      type: String,
      default: 'id'
    },
    text: {
      type: String,
      default: '暂无数据'
    },
    beforeSubmit: Function
  },
  data() {
    return {
      search: '',
      dataLoading: false,
      option: [],
      selected: []
    }
  },
  watch: {
    select: {
      handler(val) {
        this.getSelected(val)
      },
      deep: true,
      immediate: true
    }
  },
  created() {

  },
  methods: {
    getSelected(arr) {
      const len = arr.length
      const list = []
      for(let i = 0; i < len; i++) {
        list.push(arr[i])
      }
      this.selected = list
    },
    handleSearch() {
      if(this.dataLoading) return this.$message.warning('数据请求中')
      if(!this.search) return this.$message.warning('请输入关键字')
      this.getData()
    },
    uniqueFunc(arr = [], key) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[key]) && res.set(item[key], 1));
    },
    getData() {
      this.dataLoading = true
      const list = this.uniqueFunc([...this.selected], 'value')
      let isCan = list.length ? true : false
      const arr = list.map(i => i.value)
      if(this.api) {
        const data = { ...this.data, [this.searchKey]: this.search }
        http.get(this.api, data).then(res => {
          this.option = res.map(i => {
            const selected = isCan ? arr.includes(i[this.value]) : false
            return { label: i[this.label], value: i[this.value], selected }
          })
        }).finally(() => this.dataLoading = false)
      } else {
        setTimeout(() => {
          const list = []
          for (let i = 1; i <= 40; i++) {
            const selected = isCan ? arr.includes(i) : false
            list.push({ label: `测试数据${i}`, value: i, selected })
          }
          this.option = list
          this.dataLoading = false
        }, 600);
      }
    },
    handleClick(item) {
      const { label, value, selected } = item
      const index = this.option.findIndex(item => item.value === value)
      this.option[index].selected = !selected
      if (selected) {
        const idx = this.selected.findIndex(item => item.value === value)
        this.selected.splice(idx, 1)
      } else {
        this.selected.push({label, value})
      }
    },
    handleClose(item) {
      const { value } = item
      const idx = this.selected.findIndex(item => item.value === value)
      this.selected.splice(idx, 1)
      const idx2 = this.option.findIndex(item => item.value === value)
      if(~idx2) {
        this.option[idx2].selected = false
      }
    },
    handleClear() {
      this.selected = []
      this.option.forEach(item => {
        item.selected = false
      })
    },
    submit() {
      if(this.beforeSubmit) {
        return this.beforeSubmit(this.selected)
      }
      this.$emit('submit', this.selected)
      return this.selected
    }
  }
};
</script>

<template>
  <div class="z-searchSelect-wrapper">
    <div class="z-searchSelect-item left" v-loading="dataLoading">
      <div class="z-searchSelect-search">
        <el-input class="input" :placeholder="placeholder" v-model="search">
          <el-button type="primary" slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
      </div>
      <div class="z-searchSelect-list left">
        <div v-if="option.length">
          <div :class="['item', item.selected && 'active']" v-for="(item, idx) in option" :key="item.value" @click="handleClick(item)">{{ item.label }}</div>
        </div>
        <template v-else>
          <slot name="empty">
            <div class="z-searchSelect-empty">
              <img class="img" src="@/assets/img/empty.png" :alt="text" />
              <p>{{ text }}</p>
            </div>
          </slot>
        </template>
      </div>
    </div>
    <div class="z-searchSelect-item">
      <div class="z-searchSelect-search right">
        <div>已选择<span v-if="selected.length">({{ selected.length }})</span></div>
        <div class="clear" @click="handleClear">清空</div>
      </div>
      <div class="z-searchSelect-list right">
        <div>
          <el-tag class="z-tag" closable v-for="item in selected" :key="item.value" @close="handleClose(item)">{{ item.label }}</el-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$primary: #1d2088;
.z-searchSelect {
  &-wrapper {
    max-width: 700rem;
    display: flex;
  }
  &-item {
    height: 500rem;
    padding: 10rem;
    flex: 1;
    flex-shrink: 0;
    border: solid 1rem #eee;
    border-radius: 8rem;
    box-sizing: border-box;
    &.left {
      margin-right: 10rem;
    }
  }
  &-search {
    .input {
      width: 100% !important;
    }
    &.right {
      color: #999;
      display: flex;
      justify-content: space-between;
      .clear {
        cursor: pointer;
        user-select: none;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  &-list {
    height: calc(500rem - 80rem);
    margin: 10rem 0;
    overflow-y: auto;
    .item {

      height: 40rem;
      padding: 0 10rem;
      color: #666;
      line-height: 40rem;
      cursor: pointer;
      user-select: none;
    }
    &.left {
      .item {
        &:hover {
          background: #f5f7fa;
        }
        &.active {
          position: relative;
          color: $primary;
          font-weight: 600;
          &::after {
            content: "\e6da";
            position: absolute;
            right: 10rem;
            bottom: 10rem;
            font-family: element-icons;
            width: 20rem;
            height: 20rem;
            font-size: 16rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .z-tag {
      margin-right: 10rem;
      margin-bottom: 10rem;
    }
  }
  &-empty {
    .img {
      display: block;
      margin: 0 auto;
    }
    p {
      color: #999;
      text-align: center;
    }
  }
}
</style>
