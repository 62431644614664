<template>
	<edit-template type="card" @confirm="handleSubmit('ruleForm')" @cancel="handleBack" :cancelRemind="false" confirmText="提交" cancelText="返回">

    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140rem" label-position="right">
      <el-card shadow="never" class="overflow-visible border-none mb-2">
        <template #header=""><span class="tb">学生基础信息：</span></template>
        <el-form-item label="学生姓名" prop="student_id">
          <el-select v-model="formData.student_id" filterable="" remote="" reserve-keyword="" placeholder="请输入学生关键字" :remote-method="requestStudent" :loading="loading" @change="handleStudentChange">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校区">
          <el-input disabled="" v-model="info.school" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="年级">
          <el-input disabled="" v-model="info.grade" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="行政班">
          <el-input disabled="" v-model="info.class" placeholder=""></el-input>
        </el-form-item>
      </el-card>
      <el-card shadow="never" class="border-none overflow-visible mb-2">
        <template #header=""><span class="tb">艺体生转入/转出信息：</span></template>
        <el-form-item label="艺体生转入/转出" prop="type">
          <el-select :disabled="direction_study === 1 ? true : false" v-model="type" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="item in list1" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转入行政班" prop="after_class_id">
          <el-select v-model="formData.after_class_id" filterable="" remote="" reserve-keyword="" placeholder="请输入" :remote-method="requestClass" :loading="loading">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转入班主任">
          <el-input disabled="" :value="info.teacher_name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="转入教学班" prop="after_teaching_id">
          <el-button type="primary" @click="handleShowClass">请选择</el-button>
					<br>
					<div style="background: #EEF3FF;display: inline-block;margin-top: 8rem;font-size: 12rem;padding: 0 24rem">
						<i class="el-icon-info"></i>
						此处填写学生转入或转出艺体后新教学班信息，可与新班级班主任或教务处确认，数据错误会影响学生课表及学科一对一辅导。
					</div>
          <div>
            <el-tag class="mr-1 mt-1" v-for="item in selected" :key="item.value" @close="handleClose(item)">{{ item.label }}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="转入专业方向" prop="major">
          <el-select v-model="formData.major" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="item in list2" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${type === 1 ? '对应' : '艺体'}专业老师`" prop="major_teacher_id">
          <el-select v-model="formData.major_teacher_id" filterable="" remote="" reserve-keyword="" placeholder="请输入专业老师关键字" :remote-method="requestProfessionalTeacher" :loading="loading">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目组合" prop="combined_subjects">
          <el-select v-model="formData.combined_subjects" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="item in list3" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${type === 1 ? '转入' : '转出'}艺体时间`" prop="art_at">
          <el-date-picker v-model="formData.art_at" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <template v-if="type === 1">
          <el-form-item label="应缴总金额" prop="payable_amount">
            <el-input type="number" v-model="formData.payable_amount" placeholder="根据现行财务制度计算，由财务复核"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="remark">
            <el-input type="textarea" v-model="formData.remark" :maxlength="300" show-word-limit="" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="转出原因" prop="describe" v-else="">
          <el-input type="textarea" v-model="formData.describe" :maxlength="300" show-word-limit="" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入"></el-input>
        </el-form-item>
      </el-card>
    </el-form>

    <Dialog :visible.sync="visible" title="请选择所有教学班" :beforeSubmit="handleDialogSubmit">
      <SearchSelect ref="SearchBox" placeholder="请输入教学班名称" label="class_name" searchKey="class_name" api="/api2/api/common/screen/teaching-class" :data="{ school_id: this.info.school_id, grade_id: this.info.grade_id }" :select="selected"></SearchSelect>
    </Dialog>
    <Dialog :visible.sync="visible2" :showHeader="false" :showCancelButton="false" :closeOnClickModal="false" size="small" submitText="我知道了" buttonAlign="center" @submit="handleSure">
      <div class="pt-3 pb-1 px-1 t-c">{{ showMsg }}</div>
    </Dialog>
</edit-template>
</template>

<script>
import Dialog from "@/components/common/Dialog";
import SearchSelect from "@/components/common/SearchSelect";
import {as2_apply_check, as2_apply_store, as2_combined_list, as2_get_class_teacher, as2_get_student, as2_major_list, as2_search_class, as2_search_student, as2_search_teacher} from "@/api";
import {formDataBackMixin} from '@/utils/mixins'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components: { Dialog, SearchSelect },
  mixins: [formDataBackMixin],
  data() {
    const validateTotal = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入应缴总金额'))
      } else if(Number(value) < 0){
        callback(new Error('应缴总金额为非负数'))
      } else {
        const str = value + ''
        const arr = str.split('.')
        if(arr[1] && arr[1].length > 2) {
          callback(new Error('最多输入2位小数'))
        }
        callback();
      }
    }
    return {
      id: '',
      visible: false,
      visible2: false,
      showMsg: '',
      selected: [],
      info: {
        school: '',
        grade: '',
        class: '',
        school_id: '',
        grade_id: '',
        class_id: '',
        teacher_name: ''
      },
      direction_study: '',
      type: 1,
      formData: {
        student_id: '',
        after_class_id: '',
        teacher_id: '',
        after_teaching_id: '',
        major: '',
        direction_study: '',
        major_teacher_id: '',
        combined_subjects: '',
        art_at: '',
        payable_amount: '',
        remark: '',
        describe: ''
      },
      rules: {
        student_id: [{ required: true, message: '学生姓名不能为空', trigger: 'change' }],
        after_class_id: [{ required: true, message: '请输入', trigger: 'change' }],
        after_teaching_id: [{ required: true, message: '请选择', trigger: 'change' }],
        major: [{ required: true, message: '请选择', trigger: 'change' }],
        major_teacher_id: [{ required: true, message: '请输入', trigger: 'change' }],
        combined_subjects: [{ required: true, message: '请选择', trigger: 'change' }],
        art_at: [{ required: true, message: '请选择', trigger: 'blur' }],
        payable_amount: [{ required: true, validator: validateTotal, trigger: 'blur' }],
        describe: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      loading: false,
      options1: [],
      options2: [],
      options3: [],
      list1: [
        { label: '转入', value: 1 },
        { label: '转出', value: 2 }
      ],
      list2: [],
      list3: [],
      majorList: []
    }
  },
  watch: {
    type(val) {
      const isOk = this.beforeHttp()
      if(isOk) {
        this.handleTypeChange(val)
      }
    },
    'formData.after_class_id'(val) {
      this.handleClassChange(val)
    },
    'formData.major'() {
      this.getCombinedList()
    }
  },
  methods: {
    beforeHttp() {
      if(!this.info.school_id) {
        this.$message.warning('请先选择学生')
        return false
      }
      return true
    },
    handleInit() {
      const id = this.formData.student_id
      this.handleReset('ruleForm')
      this.formData.student_id = id
      this.info.teacher_name = '请输入行政班关键字'
      this.selected = []
      this.options2 = []
      this.options3 = []
      let num = this.type === 1 ? 2 : 1
      // this.changeMajor(num)
      this.getMajorList(num)
    },
    handleTypeChange(val) {
      this.handleInit()
    },
    getMajorList(direction_study) {
      const data = { school_id: this.info.school_id, grade_id: this.info.grade_id, direction_study }
      as2_major_list(data).then(res => {
        this.formData.direction_study = direction_study
        this.list2 = res.map(item => ({ label: item.name, value: item.id }))
      })
    },
    getCombinedList() {
      if(!this.info.school_id || !this.info.grade_id || !this.formData.major) return
      const data = { school_id: this.info.school_id, grade_id: this.info.grade_id, major_study: this.formData.major, direction_study : this.formData.direction_study}
      this.formData.combined_subjects = ''
      this.list3 = []
      as2_combined_list(data).then(res => {
        const list = res.map(item => {
          const label = item.map(i => i.name).join('、')
          const value = item.map(i => i.id).join(',')
          return { label, value }
        })
        this.list3 = list
      })
    },
    handleClose(item) {
      const { value } = item
      const idx = this.selected.findIndex(item => item.value === value)
      this.selected.splice(idx, 1)
      this.changeSelected()
    },
    handleDialogSubmit(done, instance) {
      const data = this.$refs.SearchBox.submit()
      if(data.length === 0) return this.$message.warning('请选择至少一个选项');
      // 调试
      // this.selected = data;
      // this.changeSelected()
      // done();
      // 正式
      const after_teaching_id = data.map(item => item.value).join(',')
      instance.confirmButtonLoading = true
      instance.confirmButtonText = '验证中'
      as2_apply_check({ student_id: this.formData.student_id, after_teaching_id }).then(res =>{
        this.selected = data;
        this.changeSelected()
        done();
      }).finally(() => {
        instance.confirmButtonLoading = false
        instance.confirmButtonText = '确认'
      })
    },
    changeSelected() {
      this.formData.after_teaching_id  = this.selected.map(item => item.value)
      this.$refs.ruleForm.validateField('after_teaching_id ')
    },
    requestStudent(keyword) {
      if (!keyword) return this.options1 = []
      this.loading = true;
      as2_search_student({ keyword }).then(res => {
        this.options1 = res.map(item => ({ label: `${item.student_name}(${item.student_no})`, value: item.id}))
      }).finally(() => this.loading = false)
    },
    handleStudentChange(id) {
      as2_get_student({id}).then(res => {
        this.info.school = res.school
        this.info.grade = res.grade
        this.info.class = res.class
        this.info.school_id = res.school_id
        this.info.grade_id = res.grade_id
        this.info.class_id = res.class_id
        this.direction_study = res.learning_direction
        this.type = res.learning_direction
        this.handleInit()
      })
    },
    requestClass(keyword) {
      if(!keyword) return this.options2 = []
      const isOk = this.beforeHttp()
      if(!isOk) return
      this.loading = true;
      as2_search_class({ keyword, school_id: this.info.school_id, grade_id: this.info.grade_id, type: 0 }).then(res => {
        this.options2 = res.map(item => ({ label: item.class_name, value: item.id}))
      }).finally(() => this.loading = false)
    },
    handleClassChange(class_id) {
      if(!class_id) return
      as2_get_class_teacher({class_id}).then(res => {
        this.formData.teacher_id = res.teacher_id || ''
        this.info.teacher_name = res.name || '行政班暂无班主任'
      })
    },
    requestProfessionalTeacher(keyword) {
      if(!keyword) return this.options3 = []
      const isOk = this.beforeHttp()
      if(!isOk) return
      const type = this.type === 2 ? 1 : 0
      this.loading = true;
      as2_search_teacher({ keyword, school_id: this.info.school_id, type }).then(res => {
        this.options3 = res.map(item => ({ label: `${item.clerk_name} (${item.mobile})`, value: item.id }))
      }).finally(() => this.loading = false)
    },
    handleShowClass() {
      const isOk = this.beforeHttp()
      if(!isOk) return
      this.visible = true
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const type = this.type
          const { describe, remark, ...other } = this.formData
          const data = { ...other }
          data.describe = type === 1 ? remark : describe
          data.type = type
          this.$confirm('是否确认信息无误并发起企微审批？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '再看看',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                as2_apply_store(data).then((res) => {
                  const { id, status, msg } = res
                  done()
                  this.$message.success('提交成功')
                  if(status === 1001) {
                    this.visible2 = true
                    this.showMsg = msg
                    this.id = id
                    return
                  }
                  this.$router.replace('./detail?id=' + id)
                }).finally(() => {
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = '确定';
                })
              } else {
                done()
              }
            }
          })
        }
      });
    },
    handleSure() {
      this.visible2 = false
      this.$router.replace('./detail?id=' + this.id)
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
    },
    handleBack() {
      this.formBack(this.formData, ['direction_study'])
    }
  },
}
</script>

<style lang="scss" scoped></style>
